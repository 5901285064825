export const ENV = {
  ENV_TYPE: process.env.REACT_APP_ENV_TYPE,
  API_PATH: process.env.REACT_APP_API_PATH,
  TOKEN_ADDRESS: process.env.REACT_APP_TOKEN_ADDRESS,
  CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
  MAINNET_INFURA_URL: process.env.REACT_APP_MAINNET_INFURA_URL,
  INFURA_PROVIDER_API_KEY: process.env.REACT_APP_INFURA_PROVIDER_API_KEY,
  WALLET_CONNECT_CONNECTOR_PROJECT_ID: process.env.REACT_APP_WALLET_CONNECT_CONNECTOR_PROJECT_ID,
  PAYMENT_SUCCESS_REDIRECT: process.env.REACT_APP_PAYMENT_SUCCESS_REDIRECT,
  CLAIM_SUCCESS_REDIRECT: process.env.REACT_APP_CLAIM_SUCCESS_REDIRECT,
  TOKEN_AMOUNT_MULTIPLIER: process.env.REACT_APP_TOKEN_AMOUNT_MULTIPLIER,
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
};
