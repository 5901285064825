// outsource dependencies
import React from "react";

import { mainnet } from "wagmi/chains";
import { infuraProvider } from "wagmi/providers/infura";
import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { InjectedConnector } from "wagmi/connectors/injected";
import { WagmiConfig, createClient, configureChains } from "wagmi";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";

// local dependencies
import App from "./App";
import { ENV } from "./constants/env";
import {polygon} from "@wagmi/chains";

const amoyChain = {
  id: 80002,
  name: 'Amoy',
  testnet: true,
  network: 'amoy',
  nativeCurrency: {
    name: 'Polygon Amoy',
    symbol: 'POL',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161']
    },
  },
  blockExplorers: {
    default: { name: 'Oklink', url: 'https://www.oklink.com/amoy' },
    thirdweb: { name: 'Thirdweb', url: 'https://thirdweb.com/polygon-amoy-testnet' },
  },
};

const defaultChains = ENV.ENV_TYPE ==="production"? [polygon, mainnet] : [amoyChain, polygon]
const { chains, provider, webSocketProvider } = configureChains(
    defaultChains,
  [
    infuraProvider({ apiKey: ENV.INFURA_PROVIDER_API_KEY }),
    publicProvider(),
  ]
);

const client = createClient({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: "wagmi" },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: ENV.WALLET_CONNECT_CONNECTOR_PROJECT_ID,
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

function WagmiUtils () {
  return <WagmiConfig client={client}>
    <App />
  </WagmiConfig>;
}

export default WagmiUtils;
